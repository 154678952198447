import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { withLatestFrom, catchError, map, switchMap, filter } from 'rxjs/operators';

import { AuthApiActions, EquityUserSettingsActions } from '../actions';
import { EquityUserSettingsService } from '../../user-settings/equity-user-settings.service';
import * as fromRoot from '../../../reducers';

@Injectable()
export class EquityUserSettingsEffect {

    constructor(
        private actions$: Actions,
        private store: Store<fromRoot.State>,
        private equityUserSettingsService: EquityUserSettingsService
    ) {}

    public loginSuccessLoadEquityUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.LoginSuccess,
            AuthApiActions.AuthApiActionTypes.LoginSSOSuccess
        ),
        withLatestFrom(this.store.pipe(select(fromRoot.getIsEquityDealMonitorPermissions))),
        filter(([_, isPermissioned]) => isPermissioned),
        switchMap(() => of(new EquityUserSettingsActions.Load()))
    ));

    public loadSuccessEquityUserSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            EquityUserSettingsActions.EquitySettingsActionTypes.Load
        ),
        withLatestFrom(this.store.pipe(select(fromRoot.getConfigurationState))),
        switchMap(([_, configuration]) => this.equityUserSettingsService.getSettings(configuration.equityBffUrl).pipe(
            map(settings => new EquityUserSettingsActions.LoadSuccess(settings)),
            catchError((error: any) => of(new EquityUserSettingsActions.LoadFailed(error)))
        ))
    ));
}
